
const appRegistry = (state = {subCategories: [], apps: {}}, action) => {
    switch (action.type) {
        case 'ADD_APP_RECORD': {

            const apps = {...state.apps};
            apps[action.payload.id] = action.payload;

            //const subcategories = new Set([...state.subCategories]);

            /*
            if (action.payload.subCategories) {
                action.payload.subCategories.map((cat) => {
                    return subcategories.add(cat);
                })
            }

             */

            return {
                //subCategories: [...subcategories],
                apps: apps
            };
        }

        case 'CLEAR_APP_RECORDS': {

            return {
                subCategories: [],
                apps: {}
            }

        }

        default:
            return state
    }
};

export default appRegistry;
