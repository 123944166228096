
const defaultState = {
    loggedIn: false,
    accessToken: null,
    idToken: null,
    expiresIn: 0,
    expiresAt: new Date()
};

const session = (state = defaultState, action) => {
    switch (action.type) {
        case 'SESSION_LOGIN':

            var expiresAt;
            if (action.payload.expiresAt) {
                expiresAt = action.payload.expiresAt;
            } else {
                console.log("Calculating expiresAt..");
                expiresAt = new Date( Date.now() + (+action.payload.expiresIn * 1000));
            }

            console.log("ExpiresAt: " + expiresAt);

            sessionStorage.loggedIn = true;
            sessionStorage.access_token = action.payload.accessToken;
            sessionStorage.id_token = action.payload.idToken;
            sessionStorage.expires_in = action.payload.expiresIn;
            sessionStorage.expires_at = expiresAt;

            return {
                loggedIn: true,
                accessToken: action.payload.accessToken,
                idToken: action.payload.idToken,
                refreshToken: action.payload.refreshToken,
                expiresIn: action.expiresIn,
                expiresAt: expiresAt
            };

        case 'SESSION_LOGOUT':
            return {
                loggedIn: false,
                accessToken: null,
                idToken: null,
                expiresIn: 0,
                expiresAt: new Date()
            };
        default:
            return state
    }
};

export default session;
