import React from 'react';
import { Layout} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import SSOLogin from "../../security/signin/SSOLogin";

const { Content} = Layout;

export default class Home extends React.Component {

    render() {

        return (
            <Layout style={{backgroundColor: 'transparent'}}>

                <Content style={{height: '100%'}}>
                    <div style={{textAlign: 'center', marginTop: '100px', height: '100%'}}>

                        <Paragraph>
                            The Ninth-Wave Fintech Registry is your central point for financial connectivity!
                        </Paragraph>


                        <div style={{marginTop: '50px'}}>
                            <SSOLogin />
                        </div>

                    </div>

                </Content>

            </Layout>
        )

    }

}
