import React from 'react';
import {Layout} from 'antd';

const { Header, Content } = Layout;

export default class MaintenanceWindowManager extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        return (

            <Layout>

                <Header>Planned Maintenance Windows</Header>
                <Content>
                    <ul>
                        <li>Aug 30, 2019 1:00am - Aug 30, 2019 4am</li>
                    </ul>
                </Content>
            </Layout>

        );

    }

}
