
import React, {useEffect, useState} from 'react';
import {Col, Row, Skeleton} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import AppRegistryBrowser from "./AppRegistryBrowser";
import PageTitle from '../../../components/PageTitle';
import {addAppRecord} from "../../../actions/data/appRegistry";
import dataApi from "../../../airtableApi";

const ConnectedApps = (props) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setLoading(true);

        dataApi('appProfiles').select({
            maxRecords: 100,
            view: "Grid view"
        }).eachPage(function page(records, fetchNextPage) {

            records.forEach(function(record) {
                props.addAppRecord({id: record.id, ...record.fields});
            });

            fetchNextPage();

        }, function done(err) {
            if (err) console.error(err);
            setLoading(false);
        });

    }, []); // eslint-disable-line react-hooks/exhaustive-deps



    const connectedApps = {apps:{}};

    Object.entries(props.appRegistry.apps)
        .forEach(([key, app]) => {
            if (app.gotham === true) connectedApps.apps[app.id] = app;
        });



    return (
        <div>

            <Skeleton loading={loading} />

            {!loading &&
                <div>
                    <PageTitle>Welcome back, Camellia</PageTitle>
                    <h2>Manage connected applications</h2>

                    <Row type="flex">
                        <Col style={{marginTop: '20px'}}>
                            <AppRegistryBrowser {...props} hideSubcategoryFilters appRegistry={connectedApps}/>
                        </Col>
                    </Row>
            </div>

            }

        </div>
    )

};


const mapStateToProps = (state) => {
    return {
        session: state.session,
        appRegistry: state.appRegistry,
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({addAppRecord }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedApps);

