import { Typography } from 'antd';
import styled from 'styled-components';

const PageTitle = styled(Typography.Title)`
  color: #11455b !important;
  font-family: 'Museo Slab', -apple-system, 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  margin-top: 10px;
`;

export default PageTitle;
