import * as queryString from "query-string";
import {Layout, Skeleton} from "antd";
import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import AppRegistryBrowser from "./AppRegistryBrowser";
import PageTitle from '../../../components/PageTitle';
import {addAppRecord, clearAppRecords} from "../../../actions/data/appRegistry";
import dataApi from "../../../airtableApi";

const { Content } = Layout;

const AppRegistryBrowserPage = (props) => {

    const [loading, setLoading] = useState(false);

    // for modal pop
    const getQuery = queryString.parse(props.location.search);
    const category = props.match.params.category;

    useEffect(() => {

        setLoading(true);

        dataApi('appProfiles').select({
            maxRecords: 100,
            view: "Grid view"
        }).eachPage(function page(records, fetchNextPage) {

            records.forEach(function(record) {
                props.addAppRecord({id: record.id, ...record.fields});
            });

            fetchNextPage();

        }, function done(err) {
            if (err) console.error(err);
            setLoading(false);
        });

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Layout style={{backgroundColor: 'transparent'}}>
            <PageTitle>
                { category ? category : "All Applications"}
            </PageTitle>
            <Content>
                <Skeleton active loading={loading} />

                {!loading &&
                    <AppRegistryBrowser {...props} filterCategory={category} detailApp={getQuery.showApp}  />
                }

            </Content>
        </Layout>

    );

};

const mapStateToProps = (state) => {
    return {
        session: state.session,
        appRegistry: state.appRegistry
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        addAppRecord, clearAppRecords
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRegistryBrowserPage);
