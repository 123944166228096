import React from 'react';
import './App.css';
import { Layout } from 'antd';
import AppRouter from "./layout/AppRouter";
import {login, logout} from "./actions/data/session";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import TopNav from "./layout/TopNav";

const { Content } = Layout;

const App = (props) =>  {

  return (

    <div className="App">


        <Layout style={{ minHeight: '100vh' }}>
            <TopNav/>
            <Content>
                <AppRouter/>
            </Content>
        </Layout>

    </div>
  );
}


const mapStateToProps = (state) => {
    return {
        session: state.session
    }

};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({login, logout}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
