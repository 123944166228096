
const registryBrowser = (state = {filtersApplied: []}, action) => {
    switch (action.type) {

        case 'APPLY_REGISTRY_BROWSER_SUBCATEGORY_FILTER': {

            const filtersApplied = new Set([...state.filtersApplied]);
            filtersApplied.add(action.payload.category);

            return {
                filtersApplied: [...filtersApplied]
            };
        }

        case 'UNAPPLY_REGISTRY_BROWSER_SUBCATEGORY_FILTER': {

            const filtersApplied = new Set([...state.filtersApplied]);
            filtersApplied.delete(action.payload.category);

            return {
                filtersApplied: [...filtersApplied]
            };
        }

        /* When the app registry's clear action is fired, reset registryBrowser filters. */
        case 'CLEAR_REGISTRY_BROWSER_SUBCATEGORY_FILTERS': {
            return {
                filtersApplied: []
            }
        }

        default:
            return state
    }
};

export default registryBrowser;
