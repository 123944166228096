import React from 'react';
import styled from 'styled-components';
import {Menu, Avatar, Layout} from 'antd';

const { Header } = Layout;

const FlexContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: none;
  height: 100%;
  align-items: center;
`

const StickyHeader = styled(Header)`
  top: 0;
  position: sticky;
  height: 64px;
  z-index: 99;
  display: flex;
  align-items: center;
`

export default class TopNav extends React.Component {
    state = {
        current: 'mail',
    };

    handleClick = e => {
        console.log('click ', e);
        this.setState({
            current: e.key,
        });
    };

    render() {
      return (
        <StickyHeader>
          <FlexContainer>
            <img
              alt="Ninth Wave"
              src="https://res.cloudinary.com/ninth-wave/image/upload/v1568466611/FF19/NW-nav-bar-logo.svg"
              style={{maxHeight: '60%'}}
             />

            <Menu onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal" />

          </FlexContainer>
          <Avatar size="medium" src="https://res.cloudinary.com/ninth-wave/image/upload/t_medium_FFFFFF/Console/User.png" />
        </StickyHeader>
      );
    }
}
