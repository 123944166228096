import React from 'react';
import {bindActionCreators} from "redux";
import {logout} from "../../actions/data/session";
import {connect} from "react-redux";

class LogoutLink extends React.Component {

    clearSessionStore() {
        sessionStorage.clear();
        this.props.logout();
    }

    render() {

        const oauthURL = "https://fintech-registry.auth.us-east-1.amazoncognito.com";
        const logoutUrl = oauthURL + "/logout";

        const origin = window.location.origin.toString();

        const loginURLWithParams = logoutUrl
            + "?client_id=3ut34977ksddlgi43svq5mh6gs"
            + "&logout_uri=" + origin;

        return (
            <a onClick={this.clearSessionStore} href={loginURLWithParams}>Logout</a>
        );


    }

}




const mapStateToProps = (state) => {
    return {
        session: state.session
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({logout}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutLink);
