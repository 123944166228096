
const protocols = (state = { protocols: {} }, action) => {
  switch (action.type) {
      case 'ADD_PROTOCOL': {

          const protocols = {...state.protocols};
          protocols[action.payload.id] = action.payload;

          return {
              protocols
          };
      }

      case 'CLEAR_PROTOCOLS': {

          return {
              protocols: {}
          }

      }

      default:
          return state
  }
};

export default protocols;
