export const addProtocol = (record) => {
  return {
      type: 'ADD_PROTOCOL',
      payload: {
          ...record
      }
  }
};

export const clearProtocols = () => ({
  type: 'CLEAR_PROTOCOLS',
});
