import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Col, Row as FlexContainer, Tag} from "antd";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import RegistryAppCard from "./RegistryApplicationCard";
import {
    applyRegistryBrowserSubcategoryFilter, clearRegistryBrowserSubcategoryFilters,
    unapplyRegistryBrowserSubcategoryFilter
} from "../../../actions/ui/registryBrowser";
import AppDetailModal from "./AppDetailModal";

const { CheckableTag }  = Tag;


const ClickableFilter = styled(CheckableTag)`
  background-color: #626897 !important;
  border-radius: 10px !important;
  color: white !important;
  font-size: 14px !important;
  margin: 3px 8px 2px 0 !important;
  cursor: pointer !important;

  &.ant-tag-checkable-checked {
    background-color: #2a3168 !important;
  }
`

const FilterTag = (props) => {

  const { value, children, onApplyFilter, onUnapplyFilter } = props;
  const [checked, setChecked] = useState(false);
  const handleChange = checked => {
    setChecked(checked);

    if (checked) {
        onApplyFilter(props.value);
    } else {
        onUnapplyFilter(props.value);
    }
  };

  return (
      <ClickableFilter
        checked={checked}
        onChange={handleChange}
        value={value}
        children={children}
         />
  );
};

const AppRegistryBrowser = (props) => {
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [detailModalApp, setDetailModalApp] = useState(null);

  // const columns = props.columns ? props.columns : 3;

  // const cardWidth = props.cardWidth ? props.cardWidth : 402; // 350
  const cardHeight = props.cardHeight ? props.cardHeight : 465;  // 430


  const FlexItem = styled(Col)`
    flex-basis: auto;
    height: ${cardHeight}px;
    margin-bottom: 30px;
    justify-content: space-between;
    text-align: center;
  `;

  const showModal = useCallback((appId) => {
      setDetailModalApp(props.appRegistry.apps[appId]);
      setDetailModalVisible(true);
      }, [props.appRegistry]);

  const modalClosed = () => {
        setDetailModalVisible(false);
        props.history.push( props.location.pathname );
    };

  const { apps } = props.appRegistry;

  const { filterCategory, clearRegistryBrowserSubcategoryFilters } = props;

  useEffect(() => {
        clearRegistryBrowserSubcategoryFilters();
  }, [filterCategory, clearRegistryBrowserSubcategoryFilters]);


  const { detailApp } = props;
  useEffect(() => {
      detailApp && showModal(detailApp);
  }, [detailApp, showModal]);

  const filtersApplied = new Set(props.registryBrowser.filtersApplied);

  const filteredApps = Object.entries(apps)
      .filter((entry) => {
          const app = entry[1];

          if (!props.filterCategory || props.filterCategory === app.appCategory) {
              return true
          }
          return false;
      });

  const subCategories = new Set([]);
  if (! props.hideSubcategoryFilters ) {
      filteredApps.forEach((entry) => {
          const [,a] = entry;
          a.subCategories && a.subCategories.forEach((c) => subCategories.add(c));
      });
  }


  const visibleApps = filteredApps
      .filter((entry) => {
          const app = entry[1];

          const appCategories = new Set(app.subCategories);
          const qualifiedCategories = new Set(
              [ ...filtersApplied ].filter( f => appCategories.has(f) )
          );

          return filtersApplied.size > 0 ? qualifiedCategories.size > 0 : true;
      });

  return (
    <div>

        <AppDetailModal centered
                        visible={detailModalVisible}
                        onOk={ () => setDetailModalVisible(false) }
                        afterClose={ modalClosed }
                        onCancel={ () => setDetailModalVisible(false) }
                        app={detailModalApp} />

        {!props.hideSubcategoryFilters &&
        <FlexContainer style={{marginBottom: "30px"}}>
            <Col sm={{size: 24}}>
                {
                    [...subCategories].sort((a, b) => {
                        return a.toLowerCase().localeCompare(b.toLowerCase());
                    }).map((cat) => {
                        return <FilterTag key={cat} value={cat}
                                          onApplyFilter={props.applyRegistryBrowserSubcategoryFilter}
                                          onUnapplyFilter={props.unapplyRegistryBrowserSubcategoryFilter}>{cat}</FilterTag>;
                    })
                }
            </Col>
        </FlexContainer>
        }

          <FlexContainer type="flex" align="top" justify="start" gutter={{ xs: 15, md: 20 }}>
          {/* width of FlexContainer is 3 times cardWidth so there will always be 3 cards per row */}
          {/* gutter is the spacing between each card */}


          {
            visibleApps.map((entry) => {
              return  (
                <FlexItem xs={24} md={12} xl={8} xxl={6} key={entry[0]}>
                <RegistryAppCard {...props.cardprops} history={props.history}  showModal={showModal} app={entry[1]} />
                </FlexItem>
              );
            })
          }
          </FlexContainer>
    </div>

  );

};

const mapStateToProps = (state) => {
    return {
        session: state.session,
        registryBrowser: state.registryBrowser
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        applyRegistryBrowserSubcategoryFilter,
        unapplyRegistryBrowserSubcategoryFilter,
        clearRegistryBrowserSubcategoryFilters}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRegistryBrowser);
