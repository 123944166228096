export const applyRegistryBrowserSubcategoryFilter = (filterCategory) => ({
    type: 'APPLY_REGISTRY_BROWSER_SUBCATEGORY_FILTER',
    payload: {
        category: filterCategory
    }
});

export const unapplyRegistryBrowserSubcategoryFilter = (filterCategory) => ({
    type: 'UNAPPLY_REGISTRY_BROWSER_SUBCATEGORY_FILTER',
    payload: {
        category: filterCategory
    }
});

export const clearRegistryBrowserSubcategoryFilters = () => ({
    type: 'CLEAR_REGISTRY_BROWSER_SUBCATEGORY_FILTERS'
});


