import {combineReducers} from 'redux';
import session from './data/session';
import appRegistry from './data/appRegistry';
import protocols from './data/protocols';
import registryBrowser from './ui/registryBrowser';
import pendingAppRequestsNotifier from './ui/pendingAppRequestsNotifier';

const rootReducer = combineReducers({

    /* data reducers */
    session,
    appRegistry,
    protocols,

    /* UI reducers */

    registryBrowser,
    pendingAppRequestsNotifier
});

export default rootReducer;
