import React from 'react';
import {Card, Tag, Button, Typography} from "antd";
import styled from 'styled-components';

const StyledCard = styled(Card)`
  width: 100%;
  margin-bottom: 25px;
  border-radius: 4px !important;
  overflow: hidden;
  &:hover {
    box-shadow: 0 2px 8px rgba(13, 75, 80, 0.40) !important;
    cursor: default;
  }

  .ant-card-head {
    height: 140px;
    width: 100%;
    padding: 0 !important;
    verticalAlign: middle;
    textAlign: center;

    .ant-card-head-wrapper {
      height: 100%;

      .ant-card-head-title {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: auto;
          height: auto;
          max-width: calc(100% - 40px) !important;
          max-height: 120px;
          margin: 0 20px;
        }
      }
    }
  }

  .ant-card-actions {
    background-color: #a7abc8;
    height: 69px;
  }
`;

const NotClickableTag = styled(Tag)`
  background-color: #2a3168 !important;
  border-radius: 10px !important;
  color: white !important;
  font-size: 14px !important;
  margin: 3px 8px 2px 0 !important;
`;

const StyledCardBody = styled.div`
  height: 240px;
  text-align: left;
  font-size: 0.9em;
  padding-left: 20px !important;
  padding-right: 20px !important;

  .btn-more {
    background-color: transparent;
    color: rgba(25, 69, 90, 0.65);
    border: none;
    cursor: pointer;
    fontSize: 16px;
    outline: none;
  }
`;

export const StyledButton = styled(Button)`
  /* text-transform: uppercase;*/
  background-color: #3d5aaf !important;
  border-radius: 4px !important;
  letter-spacing: 1px;

  &:hover {
    color: white !important;
    background-color: #626897 !important;
  }

  .ant-typography {
    color: white;
    margin-bottom: 0;
  }
`;

const SummaryText = styled(Typography.Text)`
  color: #2a3168 !important;
  height: 200px;
  font-size: 14px;
  line-height: 17px;
`;

export default class RegistryAppCard extends React.Component {

    componentDidMount() {
        if ( this.props.popAppDetail ) {
            this.showModal();
        }
    }

    render() {

        const app = this.props.app;

        var logo = <h2>{app.appName}</h2>;

        if ( app.appLogo && app.appLogo.length > 0 ) {
            logo = <img src={app.appLogo[0].url} alt={app.appName} />;
        }

        return (

            <StyledCard
              hoverable
              key={app.id}
              size="small"
              title={logo}
              actions={[
                <StyledButton
                  type="normal"
                  shape="round"
                  size={'large'}
                  onClick={() => this.props.showModal(app.id)}
                  >
                  <Typography.Title level={4}>More Details</Typography.Title>
                </StyledButton>
              ]}
            >

              <StyledCardBody>

                  {this.props.showCategory &&
                  <div>
                      <Tag  key={app.id + 'category'}>{app.appCategory}</Tag>
                  </div>
                  }

                  <div style={{ height: 165, margin: 2}}>
                    <SummaryText>
                      {!this.props.hideSummary && app.appSummary &&
                        app.appSummary.substr(0,250)
                      }
                      {!this.props.hideSummary && app.appSummary && app.appSummary.length > 250 &&
                        <button className="btn-more" href='#' onClick={this.showModal}>...</button>
                      }
                    </SummaryText>
                  </div>

                  {app.subCategories &&
                    app.subCategories.map((cat) => (
                      <NotClickableTag color="blue" key={app.id + '-' + cat}>
                        {cat}
                      </NotClickableTag>
                    ))
                  }
              </StyledCardBody>

            </StyledCard>
        )


    }



}
