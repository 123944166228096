export const addAppRecord = (record) => {
    return {
        type: 'ADD_APP_RECORD',
        payload: {
            ...record
        }
    }
};

export const clearAppRecords = () => ({
    type: 'CLEAR_APP_RECORDS',
});
