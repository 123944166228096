export const login = (accessToken, idToken, refreshToken, expiresIn, expiresAt) => ({
    type: 'SESSION_LOGIN',
    payload: {
        accessToken: accessToken,
        idToken: idToken,
        refreshToken: refreshToken,
        expiresIn: expiresIn,
        expiresAt: expiresAt
    }
});

export const logout = () => ({
    type: 'SESSION_LOGOUT'
});
