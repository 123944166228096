import React from "react";
import {Redirect} from "react-router-dom";
import {login} from "../../actions/data/session";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";


class OAuth2Callback extends React.Component {

    constructor(props) {

        super(props);

        this.getAuthParameter = this.getAuthParameter.bind(this);

        this.state = {
            error: false,
            errorLoc: undefined,
            errorHash: undefined
        };
    }

    UNSAFE_componentWillMount() {

        const accessToken = this.getAuthParameter("access_token");
        const idToken = this.getAuthParameter("id_token");
        const expiresIn = this.getAuthParameter("expires_in");
        const refreshToken = null;

        const errorDescription = this.getAuthParameter("error_description");

        if ( errorDescription !== undefined) {
            this.setState({
                error: true,
                errorLoc: window.location,
                errorHash: window.location.hash,
                errorMessage: errorDescription
            })
        } else {
            this.props.login(accessToken, idToken, refreshToken, expiresIn);
        }

    }

    render() {

        if (! this.state.error) {
            return ( <Redirect to="/" /> )
        }

        return (
            <div>
                <div>ERROR!</div>
                <div>Location: {this.state.errorLoc.toString()}</div>
                <div>Hash: {this.state.errorHash}</div>
                <div>Error Message: {this.state.errorMessage}</div>
            </div>
        )

    }

    getAuthParameter(name) {

        var hash = window.location.hash
            .replace('#', '')
            .split("&")
            .map(v => v.split("="))
            .reduce( (pre, [key, value]) => ({ ...pre, [key]: value }), {} );

        return hash[name];
    }


};


const mapStateToProps = (state) => {
    return {
        session: state.session
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({login}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(OAuth2Callback);
