import React from 'react';
import {Button} from "antd";

const SSOLogin = () => {

    const oauthURL = "https://fintech-registry.auth.us-east-1.amazoncognito.com";
    const loginURL = oauthURL + "/login";

    const origin = window.location.origin.toString();

    const loginURLWithParams = loginURL +
          "?client_id=3ut34977ksddlgi43svq5mh6gs"
        + "&response_type=token"
        + "&redirect_uri=" + origin + "/oauth2/callback/";

    return (
        <Button type="primary" style={{width: '250px'}} onClick={() => window.location=loginURLWithParams}>Login</Button>
    );

};

export default SSOLogin;
