import React, { Fragment } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import {Layout} from "antd";
import styled from 'styled-components';

import Home from "../content/unauthenticated/Home";
import FintechSidebar from "../content/fintech/layout/FintechSidebar";
import FISidebar from "../content/institution/layout/FISidebar";
import APIManager from "../content/institution/apis/APIManager";
import MaintenanceWindowManager from "../content/institution/maintenance/MaintenanceWindowManager";
import OAuth2Callback from "../security/signin/OAuthCallback";
import {bindActionCreators} from "redux";
import {login, logout} from "../actions/data/session";
import {connect} from "react-redux";
import NewAppRequestNotification from "../content/institution/notifications/NewAppRequestNotification";
import dataApi from "../airtableApi";
import {addAppNotification} from "../actions/ui/pendingAppRequestNotifier";
import AppRegistryBrowserPage from "../content/institution/applications/AppRegistryBrowserPage";
import ConnectedApps from "../content/institution/applications/ConnectedApps";

const { Sider, Content, Footer } = Layout;

const ContentContainer = styled.div`
  margin: 0 20px
  @media (min-width: 1200px) { margin: 0 50px; }
`

class AppRouter extends React.Component {

    /* This doesn't belong here. Anyway, login management needs to be reworked pretty badly. */
    UNSAFE_componentWillMount() {
        if (sessionStorage["access_token"] ) {

            console.log("Loading tokens from sessionStorage");

            this.props.login(
                sessionStorage["access_token"],
                sessionStorage["id_token"],
                sessionStorage["refresh_token"],
                sessionStorage["expires_in"],
                Date.parse(sessionStorage["expires_at"])
            );
        }
    }

    componentDidMount() {

        const addNotification = this.props.addAppNotification;

        dataApi('notifications').select({
            maxRecords: 100,
            view: "Grid view"
        }).eachPage(function page(records, fetchNextPage) {

            records.forEach(function(record) {

                if (record.fields.notifStatus === "New") {
                    addNotification(record.id, {...record.fields });
                }

            });

            fetchNextPage();

        }, function done(err) {
            if (err) console.error(err);
        });

    }

    render() {

        const pendingNotifications = this.props.pendingAppRequestsNotifier;

        const notifier =   this.props.session.loggedIn ?  <NewAppRequestNotification notifications={pendingNotifications} /> : null;


        const fintechMode = false;

        var menu = fintechMode ? <FintechSidebar /> : <FISidebar/>;

        var routes;

        if (this.props.session.loggedIn) {
            if (fintechMode) {
                routes = <Fragment>
                            <Route path="/" exact component={AppRegistryBrowserPage}/>
                        </Fragment>
            } else {
                routes = <Fragment>
                            <Route path="/" exact component={ConnectedApps}/>

                            <Route path="/institution/app/connected" exact component={ConnectedApps}/>

                            <Route path="/institution/app/browser/:category?" component={AppRegistryBrowserPage}/>

                            <Route path="/institution/apis" component={APIManager}/>
                            <Route path="/institution/availability/maintenance-windows"
                                   component={MaintenanceWindowManager}/>
                        </Fragment>
            }
        } else {
            routes = <Fragment>
                        <Route path="/" exact component={Home}/>
                        <Route path="/oauth2/callback"  component={OAuth2Callback}  />
                    </Fragment>;

            menu = false;
        }

        return (
            <Router>

                <Layout style={{backgroundColor: 'transparent', minHeight: 'calc(100vh - 64px)'}}>
                    {menu &&
                    <Sider breakpoint="xs" width={255} collapsedWidth={0}>
                        {menu}
                    </Sider>
                    }
                    <Content>
                        <Layout>
                            <Content>
                                <div style={{marginLeft: '1px'}}>
                                { notifier }
                                </div>
        
                                <ContentContainer>
                                    {routes}
                                </ContentContainer>
                            </Content>

                            <Footer>
                                <div style={{textAlign: "center"}}>©Ninth Wave 2019. All rights reserved.</div>
                            </Footer>
                        </Layout>
                        
                    </Content>
                </Layout>

            </Router>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        session: state.session,
        pendingAppRequestsNotifier: state.pendingAppRequestsNotifier,
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({login, logout, addAppNotification}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
