import React from 'react';
import styled from 'styled-components';
import {Icon, Menu} from 'antd';
import {Link} from "react-router-dom";
import LogoutLink from "../../../security/signin/LogoutLink";

const { SubMenu } = Menu;

const StyledMenuItem = styled(Menu.Item)`

  &.ant-menu-item-selected {
    font-weight: bold;
  }
`

export default class FISidebar extends React.Component {
    handleClick = e => {
        console.log('click ', e);
    };

    render() {
        return (
            <Menu
                onClick={this.handleClick}
                mode="inline"
                defaultOpenKeys={[]}
                defaultSelectedKeys={['app_registry_manage_apps']}
            >

                <StyledMenuItem key="app_registry_manage_apps">
                    <Link  to="/institution/app/connected">Manage Applications</Link>
                </StyledMenuItem>


                <SubMenu
                    key="app_registry_discover_menu"
                    title={
                        <span>
                          <Icon type="appstore" style={{color: '#3d5aaf'}}/>
                          <span>Discover New Apps</span>
                        </span>
                                }
                            >

                     <StyledMenuItem key="app_registry_browser-business_accounting">
                        <Link to="/institution/app/browser/Business Accounting">Business Accounting</Link>
                    </StyledMenuItem>
                     <StyledMenuItem key="app_registry_browser-tax_prep">
                        <Link to="/institution/app/browser/Tax Preparation">Tax Preparation</Link>
                     </StyledMenuItem>
                     <StyledMenuItem key="app_registry_browser-payment_processing">
                        <Link to="/institution/app/browser/Payment Processing">Payment Processing</Link>
                    </StyledMenuItem>
                     <StyledMenuItem key="app_registry_browser-data_aggregation">
                        <Link to="/institution/app/browser/Data Aggregation">Data Aggregation</Link>
                    </StyledMenuItem>
                    <StyledMenuItem key="app_registry_browser-financial_planning">
                        <Link to="/institution/app/browser/Financial Planning">Financial Planning</Link>
                    </StyledMenuItem>
                    <StyledMenuItem key="app_registry_browser-wealth_management">
                       <Link to="/institution/app/browser/Wealth Management">Wealth Management</Link>
                    </StyledMenuItem>
                    <StyledMenuItem key="app_registry_browser-direct_investing">
                        <Link to="/institution/app/browser/Direct Investing">Direct Investing</Link>
                    </StyledMenuItem>



                </SubMenu>

                <SubMenu
                    key="api_mgt_menu"
                    title={
                        <span>
                          <Icon type="api" style={{color: '#3d5aaf'}}/>
                          <span>API Management</span>
                        </span>
                    }
                >

                    <StyledMenuItem key="apis_available">
                        <Link to="/institution/apis">Available Protocols</Link>
                    </StyledMenuItem>
                    <StyledMenuItem key="apis_connections">
                        <Link to="/institution/apis/connections">Manage Connections</Link>
                    </StyledMenuItem>
                    <StyledMenuItem key="apis_main_windows">
                        <Link to="/institution/apis/maint-windows">Maintenance Windows</Link>
                    </StyledMenuItem>

                </SubMenu>


                <SubMenu
                    key="sandbox_mgt_menu"
                    title={
                        <span>
                          <Icon type="code" style={{color: '#3d5aaf'}}/>
                          <span>Sandbox Management</span>
                        </span>
                    }
                >

                    <StyledMenuItem key="sandbox_mgt_branding">
                        <Link to="/institution/sandbox/branding">Branding</Link>
                    </StyledMenuItem>

                    <StyledMenuItem key="sandbox_mgt_invitations">
                        <Link to="/institution/sandbox/invitations">Send Sandbox Invitations</Link>
                    </StyledMenuItem>

                </SubMenu>



                <SubMenu
                    key="security_access_group"
                    title={
                        <span>
                          <Icon type="lock" style={{color: '#3d5aaf'}}/>
                          <span>Security & Access</span>
                        </span>
                    }
                >
                    <StyledMenuItem key="security_access_mgt">
                        <Link to="/institution/security/users">User Auth</Link>
                    </StyledMenuItem>
                </SubMenu>

                <StyledMenuItem key="Logout">
                    <LogoutLink />
                </StyledMenuItem>

            </Menu>
        );
    }
}
