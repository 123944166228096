
const pendingAppRequestsNotifier = (state = {}, action) => {
    switch (action.type) {

        case 'ADD_APP_NOTIFICATION': {

            const newState = { ...state };
            newState[action.payload.id] = action.payload;

            return  newState;
        }

        case 'ACK_APP_NOTIFICATION': {

            const newState = { ...state };
            delete newState[action.payload.id];

            return newState;

        }

        default:
            return state
    }
};

export default pendingAppRequestsNotifier;
