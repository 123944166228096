export const addAppNotification = (notificationId, notificationFields) => ({
    type: 'ADD_APP_NOTIFICATION',
    payload: {
        id: notificationId,
        ...notificationFields
    }
});

export const acknowledgeAppNotification = (notificationId) => ({
    type: 'ACK_APP_NOTIFICATION',
    payload: {
        id: notificationId
    }
});
