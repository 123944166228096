import React from 'react';
import {Button, Col, Modal, Row, Tag, Typography} from "antd";
import styled from "styled-components";

const { Title, Paragraph } = Typography;

const StyledButton = styled(Button)`
    min-width: 33%;
`

const StyledTag = styled(Tag)`
    background-color: #626897 !important;
    border-radius: 10px !important;
    color: white !important;
    font-size: 14px !important;
    margin-top: 6px !important;
`;

const StyledParagraph = styled(Paragraph)`
    color: #2a3168 !important;
    font-size: 14px;
    line-height: 17px;
    margin-right: 10px;
`;

const StyledItem = styled.li`
    color: #2a3168;
    margin-top: 6px;
`;

const AppDetailModal = (props) => {

    const app = props.app;

    if (app == null) {
        return null;
    }

    const connectBtn = <StyledButton key="connect" type="primary">Invite {app.appName} to Connect</StyledButton>;
    const manageBtn = <StyledButton key="connect" type="ghost">Manage {app.appName} Connection</StyledButton>;
    const webSiteBtn = <StyledButton key="website" type="ghost">{app.appName} Web Site</StyledButton>;

    const footerBtn = app.gotham ? manageBtn : connectBtn;

    const logo = app.appLogo ?
        <img alt={app.appName}
             style={{maxWidth: '100%', maxHeight: '150px', width: 'auto', height: 'auto'}}
             src={app.appLogo[0].url} />
        : null;

    const hasSubcategories = app.subCategories && app.subCategories.length > 0;
    const hasAppFeatures = app.appFeatures && app.appFeatures.length > 0;
    const hasBankProducts = app.bankProducts && app.bankProducts.length > 0;

    const header = <div>
        <Row type="flex"><Col style={{width: '100%', textAlign: 'center'}}>{logo}</Col></Row>
        <Row type="flex"><Col style={{width: '100%', textAlign: 'center', marginTop: '20px'}}>
            {hasSubcategories &&
            app.subCategories.map((cat) => <StyledTag key={cat}>{cat}</StyledTag>)
            }
        </Col></Row>
    </div>;

    const footer =  <Row type="flex"><Col style={{width: '100%', textAlign: 'center'}}>{webSiteBtn} {footerBtn}</Col></Row>;

    const leftProductList = [];
    const rightProductList = [];
    for (var i = 0; i < app.bankProducts.length; i++) {
        if(i % 2 === 0) { // index is even
            leftProductList.push(app.bankProducts[i]);
        } else {
            rightProductList.push(app.bankProducts[i]);
        }
    }

    return (
        <Modal {...props}
               width="800px"
               title={header}
               footer={footer}
               closeIcon={
                <img 
                    src="https://res.cloudinary.com/ninth-wave/image/upload/t_small_3D5AAF/Console/icons/UIclose.png"
                    alt="close"
                    width={15}
                />
               }
        >

            <Row type="flex">

                {/* Left Column */}
                <Col span={12}>

                    <Row type="flex" style={{marginBottom: "1em"}}>
                        <Col>
                            <Title level={3} style={{color: "#19455a"}}>About {app.appName}</Title>
                            <StyledParagraph>
                                {app.appSummary}
                            </StyledParagraph>
                        </Col>
                    </Row>
                    {hasBankProducts &&

                    <Row type="flex">

                        <Col>

                            <Title level={3} style={{color: "#19455a"}}>Supports</Title>

                            <Row type="flex">
                                <Col>
                                    <ul>
                                        {
                                            leftProductList.map((prd) => <StyledItem key={prd}>{prd}</StyledItem>)
                                        }
                                    </ul>
                                </Col>

                                <Col>
                                    <ul>
                                        {
                                            rightProductList.map((prd) => <StyledItem key={prd}>{prd}</StyledItem>)
                                        }
                                    </ul>
                                </Col>

                            </Row>

                        </Col>

                    </Row>
                    }


                </Col> {/* Left Column */}

                {/* Right Column */}
                <Col span={12}>

                    {hasAppFeatures &&
                        <Row type="flex" style={{marginBottom: "1em"}}>
                            <Col>
                                <Title level={3} style={{color: "#19455a"}}>{app.appName} Features</Title>
                                <ul>
                                    {app.appFeatures.map((feature) => <StyledItem key={feature}>{feature}</StyledItem>)}
                                </ul>

                            </Col>
                        </Row>
                    }

                    <Row type="flex">

                        <Col>
                            <Tag style={{borderRadius:"20px", backgroundColor: "#11455b", color: "white"}}>
                            {app.banksConnected} BANKS CONNECTED
                            </Tag>
                        </Col>

                    </Row>



                </Col> {/* Right Column */}
            </Row>


        </Modal>

    );

};

export default AppDetailModal;

