import React from 'react';
import styled from 'styled-components';
import {Alert} from "antd";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {acknowledgeAppNotification} from "../../../actions/ui/pendingAppRequestNotifier";

const StyledAlert = styled(Alert)`
  .ant-alert-icon {
    width: 17px;
    left: 15px;
    top: 16px;
  }
`

const handleAlertClick = (ackFunc, notificationId) => {
    ackFunc(notificationId);
}

const NewAppRequestNotification = (props) => {

    const ackNotification = props.acknowledgeAppNotification;

    return (
        <div>
        {
            Object.entries(props.notifications).map((entry) => {
                const [, notification] = entry;
                return (
                    <div onClick={() => handleAlertClick(ackNotification, notification.id)} key={notification.id}>
                        <Link to={"/institution/app/browser?showApp=" + notification.appRequested} >
                            <StyledAlert
                                type="success"
                                message={<h2>{notification.notificationMessage}</h2>}
                                icon={
                                    <img 
                                        src="https://res.cloudinary.com/ninth-wave/image/upload/t_medium_2A3168/Console/icons/UIinbox.png"
                                        alt="success"
                                   />
                                }
                                showIcon
                            />
                        </Link>
                    </div>
                );
            })

        }
        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        session: state.session,
        pendingAppRequestsNotifier: state.pendingAppRequestsNotifier
    }
};

const mapDispatchToProps = (dispatch, ackAppNotification = null) => {
    return bindActionCreators({acknowledgeAppNotification}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAppRequestNotification);

