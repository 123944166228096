import React, {useEffect, useState} from 'react';
import {Layout, Skeleton, Descriptions} from 'antd';
import styled from 'styled-components';
import {connect} from 'react-redux';

import PageTitle from '../../../components/PageTitle';
import { addProtocol, clearProtocols } from '../../../actions/data/protocols';
import dataApi from '../../../airtableApi';

const { Content } = Layout;

const StyledDescriptions = styled(Descriptions)`
    margin-bottom: 25px;

    .ant-descriptions-view {
        background-color: #a7abc8;
    }
`;

const APIManager = ({ protocols, addProtocol }) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setLoading(true);

        dataApi('protocols').select({
            maxRecords: 100,
            view: 'Grid view',
            enabled: true
        }).eachPage((records, fetchNextPage) => {

            records.forEach((record) => {
                addProtocol({ id: record.id, ...record.fields });
            });

            fetchNextPage();

        }, (err) => {
            if (err) console.error(err);
            setLoading(false);
        });

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (

        <Layout>
            <PageTitle>API Manager</PageTitle>
            <Content>
                <Skeleton active loading={loading} />
                {!loading && Object.entries(protocols).map(([id, protocol]) => (
                    <StyledDescriptions key={id} title="Protocol Info" layout="vertical" bordered>
                        <Descriptions.Item label="Name">{protocol.protocolName}</Descriptions.Item>
                        <Descriptions.Item label="Features">{protocol.features.join(', ')}</Descriptions.Item>
                        <Descriptions.Item label="Available Versions">{protocol.availVersions}</Descriptions.Item>
                        <Descriptions.Item label="Summary">{protocol.protocolSummary}</Descriptions.Item>
                    </StyledDescriptions>
                ))}
            </Content>
        </Layout>

    );
};

const mapStateToProps = ({ protocols: { protocols } }) => ({ protocols })

const mapDispatchToProps = { addProtocol, clearProtocols };

export default connect(mapStateToProps, mapDispatchToProps)(APIManager);

